import type { RouteLocationNormalized } from 'vue-router'
import { useSelectionStore } from '~/store/selection-store'
import { useUserStore } from '~/store/user-store'
import type { Country } from '~/types/Country'
import type { Partner } from '~/types/Partner'
import { countries } from '~/constants'

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized): void => {
  if (import.meta.client) {
    const selectionStore = useSelectionStore()
    const userStore = useUserStore()

    // Reset possible previous selections, subscription and payment
    selectionStore.reset()
    userStore.resetSubscriptionAndPayment()

    // If either partnerId or countryId are not present continue, else set both in the store
    const partnerId = to.query.partnerId as string
    const countryId = countries.find((country: Country) => country.partners.find((partner: Partner) => partner.id === partnerId))?.id
    if (!partnerId || !countryId) {
      return
    }

    // Set multiple value in a single call in the store
    selectionStore.$patch({
      selectedCountryId: countryId,
      selectedPartnerId: partnerId,
      selectedStore: null
    })
  }
})
